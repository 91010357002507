<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门诊叫号</el-breadcrumb-item>
            <el-breadcrumb-item>诊室设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" style="text-align: center">
            <el-form-item label="院区">
                <select-hospital v-model="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item label="科室">
                <select-department v-model="search.department_id" :hospital_id="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="search.keywords" size="small" placeholder="关键词" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain size="small" @click="handleSearch">检索</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom:10px">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">新增诊室</el-button>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="warning"
                                   icon="el-icon-edit"></el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.doctor.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <el-dialog title="设置" :visible.sync="dialogVisible" width="40%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="院区" prop="hospital_id">
                    <selectHospital v-model="form.hospital_id"></selectHospital>
                </el-form-item>
                <el-form-item label="科室" prop="department_id">
                    <select-department v-model="form.department_id" :hospital_id="form.hospital_id"></select-department>
                </el-form-item>
                <el-form-item label="诊室名称" prop="name">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="出诊医生" prop="doctor_id">
                    <select-doctor v-model="form.doctor_id" :hospital_id="form.hospital_id"
                                   :department_id="form.department_id"></select-doctor>
                </el-form-item>
                <el-form-item label="备注" prop="summary">
                    <el-input v-model="form.summary" style="width:80%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import SelectDepartment from "@/components/form/selectDepartment";
    import SelectDoctor from "@/components/form/selectDoctor";

    export default {
        components: {SelectDoctor, SelectDepartment},
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: '160'},
                        {title: '科室', field: 'department.name', width: '100'},
                        {title: '诊室', field: 'name', width: '100'},
                        {title: '医生', field: 'doctor.name', width: '220'},
                        {title: '备注', field: 'summary', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                search: {},
                dialogVisible: false,
                form: {
                    hospital_id: null,
                    department_id: null,
                    doctor_id: null,
                    name: '',
                    summary: '',
                },
                rules: {
                    hospital_id: [
                        {required: true, message: '请选择院区', trigger: 'blur'},
                    ],
                    department_id: [
                        {required: true, message: '请选择科室', trigger: 'blur'},
                    ],
                    doctor_id: [
                        {required: true, message: '请选择医生', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {hospital_id: null, department_id: null, doctor_id: null, name: '', summary: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async addSubmit() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/room/${this.form.id}` : `/mingde/room/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async handleDel(index, row) {
                this.$refs[`popover-${index}`].doClose()
                const resp = await this.$http.delete(`/mingde/room/${row.id}`)
                if (resp.data.code == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.msg)
                this.getData()
            },
            async getData() {
                this.loading = true
                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                const resp = await this.$http.get('/mingde/room/', {params: params})
                this.table.data = resp.data.data.data || []
                this.table.total = resp.data.data.total
                this.loading = false
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        text-align: center;
    }

    .expend-times {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        .time {
            width: 200px;
        }
    }
</style>
